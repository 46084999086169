import React from 'react';
import { useForm, ValidationError } from '@statickit/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("contactForm");
  if (state.succeeded) {
    return <div className="flex justify-center px-6 my-12 text-xl text-purple-600"><p>Danke für Ihre Nachricht! </p></div>;
  }
  return (
    <div className="w-full lg:flex mr-5 p-4">
        <div className="w-full xl:w-3/4 lg:w-11/12 flex bg-gray-100 dark:bg-gray-800 px-6 py-6 justify-center items-center">
            <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 dark:text-gray-300 text-xs font-bold mb-2" for="name">
                            Name
                            <input className="contact-label dark:bg-gray-800" id="name" name="name" type="text" placeholder="Muster" />
                        </label>
                        
                        <ValidationError 
                            prefix="Name" 
                            field="name"
                            errors={state.errors}
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase dark:text-gray-300 tracking-wide text-gray-700 text-xs font-bold mb-2" for="last-name">
                            Nachname
                            <input className="contact-label dark:bg-gray-800" id="last-name" name="last-name" type="text" placeholder="Mann" />
                        <ValidationError 
                            prefix="LastName" 
                            field="last-name"
                            errors={state.errors}
                        />
                        </label>
                        
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block uppercase dark:text-gray-300 tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
                            E-mail
                            <input className="contact-label dark:bg-gray-800" id="email" name="email" type="email" />
                        <ValidationError 
                            prefix="Email" 
                            field="email"
                            errors={state.errors}
                        />
                        </label>
                       
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3">
                        <label className="block dark:text-gray-300 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="message">
                            Nachricht
                            <textarea className=" no-resize contact-label dark:bg-gray-800 h-48 resize-none" id="message" name="message"></textarea>
                        <ValidationError 
                            prefix="Message" 
                            field="message"
                            errors={state.errors}
                        />
                        </label>
                        
                    </div>
                </div>
                <div className="md:flex md:items-center">
                    <div className="md:w-1/3">
                        <button className="contact-send" type="submit" disabled={state.submitting}>
                        Senden
                        </button>
                    </div>
                        <div className="md:w-2/3"></div>
                </div>
                
            </form>
        
        </div> 
        <div className="w-full xl:w-3/4 lg:w-11/12 flex px-8 mt-4 md:mt-0">
        <div className="max-w-4xl px-10 my-4 py-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
        <div className="mt-2">
            <p className="text-2xl text-gray-700 font-bold dark:text-gray-200">Kontakt Info</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non</p>
        </div>
    </div>
        </div>
    </div>
  );
}
export default ContactForm;

