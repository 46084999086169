import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contactForm"


const KontaktPage = () => (
<Layout>
   <Contact />
</Layout>
)

export default KontaktPage